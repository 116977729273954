@use "../foundation/variables" as v;
@use "../foundation/common" as c;

//コンテンツ幅内でめいっぱい広げる
.l__content_width_l {
  max-width: calc(v.$pc_min_l + v.$body_pd + v.$body_pd);
  width: 100%;
  margin: 0 auto;
  padding: 0 v.$body_pd;
}
.l__content_width_m {
  max-width: calc(v.$pc_min_m + v.$body_pd + v.$body_pd);
  width: 100%;
  margin: 0 auto;
  padding: 0 v.$body_pd;
}
.l__content_width_s {
  max-width: calc(v.$pc_min_s + v.$body_pd + v.$body_pd);
  width: 100%;
  margin: 0 auto;
  padding: 0 v.$body_pd;
}
//このコンテナの中身を上下左右中央寄せ
.l__gentle_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1ch;
}

/*=======================================
  flex系
=======================================*/

//常にflex
.l__flexbox {
  display: flex;
}
// 均等配置
.l__flex_space_between {
  justify-content: space-between;
}
//flex逆方向
.l__flex_reverse {
  flex-flow: row-reverse;
}
//上下中央寄せ
.l__flex_ai_center {
  align-items: center;
}
//左右中央寄せ
.l__flex_jc_center {
  justify-content: center;
}
//高さを揃える
.l__flex_stretch {
  align-items: stretch;
}
//PCの場合のみflex
.l__flexbox_pc {
  display: flex;
  @include c.tab() {
    display: block;
  }
}
//PCとtabの場合のみflex
.l__flexbox_pc_tab {
  display: flex;
  @include c.sp() {
    display: block;
  }
}
//tabとspの場合のみflex
.l__flexbox_tab_sp {
  display: block;
  @include c.tab() {
    display: flex;
  }
}