@use "../foundation/variables" as v;
@use "../foundation/common" as c;

/*==============================
見出し系
==============================*/
.c__head_primary {
  font-size: 30px;
  font-weight: v.$fw_bold;
  color: v.$letter_primary;
  letter-spacing: 0.06em;
  line-height: 35px;
}
.c__head_secondary {
  font-size: 18px;
  font-weight: v.$fw_bold;
  color: v.$letter_primary;
  line-height: 30px;
}

/*==============================
文字種
==============================*/
.c__common_text {
  font-size: 16px;
  color: v.$letter_primary;
  line-height: 30px;
  @include c.sp {
    font-size: 14px;
    line-height: 24px;
  }
}
.c__common_text_secondary {
  font-size: 14px;
  color: v.$letter_primary;
  line-height: 26px;
  @include c.sp {
    font-size: 14px;
    line-height: 24px;
  }
}
// 注意書き
.c__annotation {
  font-size: 12px;
  color: v.$letter_secondary;
}

/*==============================
ボタン系
==============================*/
.c__cv_button {
  position: relative;
  text-align: center;
  margin: 0 auto;
  max-width: 380px;
  min-width: 200px;
  font-weight: v.$fw_bold;
  letter-spacing: 0.05em;
  &:hover {
    transition: 0.13s;
    opacity: 0.9;
  }
  a {
    display: block;
    padding: 24px 1em;
    width: 100%;
    border-radius: 100vh;
    width: 100%;
    color: white;
    background: #ff874b;
    border-bottom: solid 5px #bf7550;
    margin: 0 auto;
    &:active {
      border-bottom: solid 5px transparent;
      transform: translateY(5px);
    }
  }
  @include c.sp {
    margin: 0 auto;
    width: clamp(240px, 70vw, 380px);
    font-size: 16px;
  }
}

/*==============================
下向き三角
==============================*/
.c__triangle_wrapper {
  transform: rotate(180deg);
  .triangle,
  .triangle::before,
  .triangle::after {
    width: 12px;
    height: 12px;
    background: v.$kn_yellow;
  }
  .triangle::before,
  .triangle::after {
    content: "";
    position: absolute;
  }
  .triangle {
    position: relative;
    border-top-left-radius: 50%;
    transform: rotate(30deg) skewY(30deg) scaleX(0.86666);
  }
  .triangle::before {
    border-top-right-radius: 50%;
    transform: skewX(-45deg) translateX(50%);
  }
  .triangle::after {
    border-bottom-left-radius: 50%;
    transform: skewY(-45deg) translateY(50%);
  }
}

/*=======================================
  コンテンツエリア
=======================================*/
.wp_content_area {
  h2 {
    font-size: 20px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  h3 {
    font-size: 18px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  h4 {
    font-size: 16px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  h5 {
    font-size: 14px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  h6 {
    font-size: 14px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  p {
    font-size: 14px;
    line-height: 1.8;
    margin-bottom: 26px;
    strong {
      font-weight: v.$fw_bold;
    }
    em {
      font-style: italic;
    }
    u {
      text-decoration: underline;
    }
    s {
      text-decoration: line-through;
    }
    a {
      text-decoration: underline;
      color: v.$letter_primary;
      transition: 0.15s;
      &:hover {
        opacity: 0.7;
      }
    }
    img {
      margin: 20px auto;
      width: 100%;
    }
  }
  blockquote {
    &:not(.Twitter) {
      position: relative;
      padding: 10px 60px;
      box-sizing: border-box;
      font-style: italic;
      background: #fafafa;
      color: #777;
      border-left: 4px solid #ccc;
      margin: 20px auto;
      &::before {
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 15px;
        content: "\f10d";
        font-family: "Font Awesome 5 Free";
        color: #999999;
        font-size: 10px;
        line-height: 1;
        font-weight: 900;
      }
      &::after {
        display: inline-block;
        position: absolute;
        bottom: 15px;
        right: 15px;
        content: "\f10e";
        font-family: "Font Awesome 5 Free";
        color: #999999;
        font-size: 10px;
        line-height: 1;
        font-weight: 900;
      }
      p {
        padding: 0;
        margin: 7px 0;
        line-height: 1.7;
        a {
          text-decoration: underline;
          color: v.$letter_primary;
        }
      }
    }
  }
  ul {
    list-style-type: disc;
    padding: 14px 14px 14px 34px;
    li {
      font-size: 14px;
      line-height: 2;
    }
  }
  ol {
    list-style-type: decimal;
    padding: 14px 14px 14px 30px;
    li {
      padding-left: 5px;
      font-size: 14px;
      line-height: 2;
    }
  }
  // コードスニペット
  pre {
    padding: 0px;
    white-space: pre-wrap;
    code {
      padding: 20px;
      font-size: 15px;
      letter-spacing: 0.06em;
      line-height: 1.7em;
      span {
        max-width: 100%;
      }
    }
  }
  figcaption {
    font-size: 14px;
    line-height: 1.8;
  }
  .iframe_wrap {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  @mixin fontawesome($type) {
    @if $type == fas {
      font-family: "Font Awesome 5 Free";
      font-weight: $fontawesome_weight_fas;
    } @else if $type == far {
      font-family: "Font Awesome 5 Free";
      font-weight: $fontawesome_weight_far;
    } @else if $type == fal {
      font-family: "Font Awesome 5 Free";
      font-weight: $fontawesome_weight_fal;
    } @else if $type == fab {
      font-family: "Font Awesome 5 Brands";
      font-weight: $fontawesome_weight_fab;
    }
  }
}

/*=======================================
  フォーム系
=======================================*/
@mixin form_base($height, $font_size: 16px) {
  width: 100%;
  height: $height;
  font-family: v.$noto; //明示
  font-size: $font_size;
  padding: 16px 20px;
  transition: 0.15s;
  color: v.$letter_primary;
  background-color: #f8f8f8;
  border: 1px solid #dddddd00;
  outline: 0;
  border-radius: 100vh;
  &:focus {
    border: 1px solid #ddddddff;
    outline: 0;
    border-radius: 100vh;
    box-shadow: 0px 0px 3px 0px #eee; //横ずれ 縦ずれ ぼかし度 サイズ 色
  }
  &::placeholder {
    color: #d7d7d7;
    font-family: v.$noto; //明示
    font-size: $font_size;
  }
  &::-ms-input-placeholder {
    color: #d7d7d7;
    font-family: v.$noto; //明示
    font-size: $font_size;
  }
}
.c__contact_label {
  font-size: 16px;
  font-weight: v.$fw_bold;
  color: v.$letter_primary;
  letter-spacing: 0.06em;
}
.c__contact_text {
  @include form_base(50px, 16px);
  @include c.sp {
    height: 48px;
    padding: 20px 18px;
  }
}
.mwform-checkbox-field {
  .c__contact_checkbox {
    display: none;
    + span {
      position: relative;
      margin-left: 28px;
    }
    + span:before {
      position: absolute;
      left: -23px;
      top: 5px;
      content: "";
      background-image: url(../img/unchecked_box.svg);
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      opacity: 1;
      transition: 0.15s;
    }
    + span:after {
      position: absolute;
      left: -23px;
      top: 5px;
      content: "";
      background-image: url(../img/checked_box.svg);
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      opacity: 0;
      transition: 0.1s;
    }
    &:checked + span:after {
      opacity: 1;
      z-index: 1;
    }
  }
}
.c__contact_textarea {
  @include form_base(300px);
  border-radius: 24px;
  resize: none;
  &:focus {
    outline: 1px solid #ddd;
    box-shadow: 0px 0px 3px 0px #eee inset;
    outline-offset: -1px;
    border-radius: 24px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    box-shadow: none;
  }
}
@mixin submit_button($height, $width) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  font-size: 18px;
  font-family: v.$noto; //明示
  font-weight: v.$fw_bold;
  text-decoration: none;
  color: v.$letter_primary;
  background: v.$kn_yellow;
  border: solid 2px v.$kn_yellow;
  -webkit-appearance: none; //safariの角丸対策
  transition: 0.1s;
  cursor: pointer;
  &:hover {
    background: white;
    color: #111;
  }
}
.c__submit_primary {
  @include submit_button(60px, 180px);
  border-radius: 100vh;
  &:disabled {
    @include submit_button(60px, 180px);
    color: white;
    background: #ccc;
    border: solid 0px #ffffff00;
    border-radius: 100vh;
    transition: 0.15s;
    pointer-events: none;
  }
  @include c.sp {
    height: 48px;
  }
}
.required::after {
  content: "必須";
  color: v.$notify_red;
  font-size: 14px;
  padding-left: 10px;
}

/*=======================================
  アニメーション系
=======================================*/
//フェードイン
.c__js_fade {
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
  @include c.tran_delay(0.05);
}
.c__loader,
.c__loader:before,
.c__loader:after {
  border-radius: 100vh;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: load_dots 1.4s infinite ease-in-out;
}
.c__loader {
  color: v.$kn_yellow;
  font-size: 6px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.1s;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -5.5em;
    animation-delay: -0.2s;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 5.5em;
  }
}
@keyframes load_dots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  30% {
    box-shadow: 0 2.5em 0 0;
  }
  70% {
    box-shadow: 0 2.5em 0 0;
  }
}