$kn_yellow: #FFD800;
$notify_red: #DB0000;
$error_red: #FFF2F2;
$letter_primary: #232323;
$letter_secondary: #D7D7D7;
$link_blue: #2b7bb9;
$selection_bg: $kn_yellow;
$selection_text: $letter_primary;

// フォント系
$noto: YakuHanJP, "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //regular: 400, medium: 500, bold: 700
$fw_bold: 700;
$fw_normal: 400;
$fontawesome_fas: 900;
$fontawesome_far: 400;
$fontawesome_fal: 300;
$fontawesome_fab: 400;

$header_height: 80px;
$header_height_sp: 60px;

// メディアクエリー用
$pc_min_l: 1140px;
$pc_min_m: 910px;
$pc_min_s: 642px;
$tab_max: 768px;
$sp_max: 640px;
$layout_min: 320px;
$body_pd: 35px;

// 縦横比を固定する場合に使用
$ratio_16_9: 56.25%;
$ratio_4_3: 75%;
$ratio_3_2: 66.67%;
$ratio_1_1: 100%;

/*=======================================
◆z-index管理


z-index: 9800;
#pagetop_button

z-index: 9500;
#mouse_stalker

z-index: 9000;
#loader_wrap

z-index: 20;
└#header_toggle

z-index: 10;
└#header
└.header_inner_sp
└#footer

z-index: 1;
└.c__contact_checkbox&:checked + span:after
=======================================*/