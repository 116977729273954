@use "../foundation/variables" as v;
@use "../foundation/common" as c;

/*=======================================
  ヘッダー
=======================================*/
#header {
  width: 100%;
  font-family: v.$noto;
  color: v.$letter_primary;
  transition: 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .header_inner_pc {
    background: #fff;
    height: v.$header_height;
    @include c.sp {
      display: none;
    }
    .nav_wrapper {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .header_l {
      display: flex;
      align-items: center;
      .logo {
        width: 30px;
        margin: 0 auto;
        a {
          display: block;
        }
        // a:hover {
        //   transition: 0.15s;
        //   opacity: 0.7;
        // }
      }
    }
    .header_r {
      display: flex;
      align-items: center;
      .gnav {
        display: flex;
        font-size: 14px;
        font-weight: v.$fw_bold;
        gap: 32px;
        // li a {
        //   @include c.mq_hover_device {
        //     &:hover {
        //       transition: 0.15s;
        //       opacity: 0.7;
        //     }
        //   }
        // }
        @include c.tab {
          gap: 0 30px;
        }
      }
      .header_sns {
        display: flex;
        align-items: center;
        .icon {
          margin: 0 auto;
          margin-left: 40px;
          height: 30px;
          width: 30px;
          a {
            display: block;
            width: 100%;
            height: 100%;
            img {
              width: auto;
              height: 100%;
            }
            // @include c.mq_hover_device {
            //   &:hover {
            //     transition: 0.15s;
            //     opacity: 0.7;
            //   }
            // }
          }
          @include c.tab {
            margin-left: 30px;
          }
        }
        .tw {
          height: 28px;
          @include c.tab {
          }
        }
        .ig {
          // width: 30px;
          @include c.tab {
            // width: 26px;
          }
        }
        .fb {
          // width: 30px;
          @include c.tab {
            // width: 26px;
          }
        }
      }
    }
  }
  .header_inner_sp {
    position: fixed;
    height: 50px;
    background: white;
    top: 0;
    left: 0;
    z-index: 10;
    display: none;
    @include c.sp {
      display: block;
    }
    .header_l {
      .logo {
        position: fixed;
        top: 10px;
        left: 35px;
        width: 30px;
        margin: 0 auto;
      }
    }
    #sp_menu {
      width: 203px;
      transform: translateX(100%);
      transition: all 0.2s ease-in-out;
      font-family: v.$noto;
      color: v.$letter_primary;
      position: fixed;
      top: v.$header_height_sp;
      right: 0;
      &.active {
        transform: translateX(0%);
      }
      .gnav {
        font-size: 18px;
        font-weight: v.$fw_bold;
        li {
          margin: 0 -3px 10px auto;
          a {
            display: block;
            margin: 0 0 0 auto;
            width: 223px;
            height: 48px;
            background: v.$kn_yellow;
            border: v.$kn_yellow 3px solid;
            border-radius: 24px 0 0 24px;
            padding: 11px 0px 10px 21px;
            transition: 0.15s;
            @include c.mq_hover_device {
              &:hover {
                transform: translateX(-20px);
              }
            }
          }
        }
      }
      .header_sns {
        .icon_wrapper {
          margin: 0 -3px 10px auto;
          a {
            display: block;
            margin: 0 0 0 auto;
            width: 223px;
            height: 48px;
            background: v.$kn_yellow;
            border: v.$kn_yellow 3px solid;
            border-radius: 24px 0 0 24px;
            padding: 8px 0px 0px 22px;
            font-size: 20px;
            transition: 0.15s;
            @include c.mq_hover_device {
              &:hover {
                transform: translateX(-20px);
              }
            }
            img {
              width: auto;
              height: 28px;
            }
          }
          // .tw {
          //   width: 28px;
          // }
          // .ig {
          //   width: 27px;
          // }
          // .fb {
          //   width: 27px;
          // }
        }
      }
    }
  }
  #header_toggle {
    position: fixed;
    right: 0;
    top: 0;
    background: v.$kn_yellow;
    border: v.$kn_yellow 0.2rem solid;
    width: 50px;
    height: 50px;
    border-radius: 100vh;
    cursor: pointer;
    z-index: 20;
    @include c.mq_hover_device {
      &:hover {
        transition: 0.15s;
        background: white;
      }
    }
    span {
      display: block;
      position: absolute;
      width: 20px;
      border-bottom: solid 3px v.$letter_primary;
      border-radius: 100vh;
      -webkit-transition: 0.15s ease-in-out;
      -moz-transition: 0.15s ease-in-out;
      transition: 0.15s ease-in-out;
    }
    &.active {
      span {
        border-bottom: solid 3px v.$letter_primary;
      }
    }
  }
  #header_toggle span:nth-child(1) {
    top: 13px;
    left: 12px;
  }
  #header_toggle span:nth-child(2) {
    top: 21px;
    left: 12px;
  }
  #header_toggle span:nth-child(3) {
    top: 29px;
    left: 12px;
  }
  #header_toggle.active span:nth-child(1) {
    width: 25px;
    transform: translate3d(-3px, 8px, 0px) rotate(45deg);
  }
  #header_toggle.active span:nth-child(2) {
    opacity: 0;
    width: 25px;
    transform: translate3d(-3px, 8px, 0px) rotate(45deg);
  }
  #header_toggle.active span:nth-child(3) {
    width: 25px;
    transform: translate3d(-3px, -8px, 0px) rotate(-45deg);
  }
  #overlay {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    transition: 0.3s;
    &.active {
      visibility: visible;
      opacity: 0.97;
    }
  }
}
#pagetop_button {
  position: fixed;
  bottom: -200px;
  right: 20px;
  z-index: 9800;
  transition: 0.4s;
  &.active {
    bottom: 20px;
  }
  a {
    display: block;
    position: relative;
    background-color: white;
    border: 2px solid v.$kn_yellow;
    border-radius: 100vh;
    text-decoration: none;
    width: 50px;
    height: 50px;
    transition: 0.2s;
    @include c.sp {
      background-color: rgba(255, 255, 255, 0.93);
    }
    .c__triangle_wrapper {
      display: inline-block;
      position: absolute;
      top: 17px;
      left: 19px;
      transform: rotate(0deg);
      transition: 0.2s;
      .triangle,
      .triangle::before,
      .triangle::after {
        width: 9px;
        height: 9px;
      }
    }
    @include c.mq_hover_device {
      &:hover {
        background-color: v.$kn_yellow;
        .c__triangle_wrapper {
          transition: 0.2s;
          transform: translateY(-40%);
          .triangle,
          .triangle::before,
          .triangle::after {
            background: white;
          }
        }
      }
    }
  }
}

/*=======================================
  中ページ
=======================================*/
.p__home {
  .first_view {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: calc(100vh - v.$header_height);
    margin-top: v.$header_height;
    overflow: hidden;
    @include c.sp {
      height: calc(100vh - v.$header_height_sp);
    }
    .eyecatch {
      position: absolute;
      top: calc(40vh - v.$header_height);
      @include c.sp {
        top: calc(32vh - v.$header_height_sp);
      }
    }
    .logo_wrapper {
      position: relative;
    }
    .logo {
      position: absolute;
      top: 0;
      left: 0;
      width: clamp(90px, 22vmin, 140px);
      margin-bottom: 4vh;
      animation: blinking 10s steps(1, end) infinite;
    }
    @keyframes blinking {
      0% {
        opacity: 1;
      }
      30% {
        opacity: 0;
      }
      30.5% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      90.5% {
        opacity: 0;
      }
      91% {
        opacity: 1;
      }
      92% {
        opacity: 0;
      }
      92.5% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    .logo_closed {
      width: clamp(90px, 22vmin, 140px);
      margin-bottom: 4vh;
    }
    .copy {
      font-size: clamp(15px, 4vmin, 20px);
    }
    .c__triangle_wrapper {
      position: absolute;
      top: calc(90vh - v.$header_height);
      @include c.sp {
        top: calc(78vh - v.$header_height);
      }
      animation: appeal 5s ease-in-out infinite;
    }
    @keyframes appeal {
      0% {
        transform: rotate(180deg) translateY(0);
      }
      14% {
        transform: rotate(180deg) translateY(0);
      }
      16% {
        transform: rotate(180deg) translateY(-80%);
      }
      18% {
        transform: rotate(180deg) translateY(0);
      }
      20% {
        transform: rotate(180deg) translateY(-80%);
      }
      22% {
        transform: rotate(180deg) translateY(0);
      }
      100% {
        transform: rotate(180deg) translateY(0);
      }
    }
  }
  .head_pickup {
    margin-bottom: 20px;
  }
  #pickup_slider {
    margin-bottom: clamp(60px, 15vw, 200px);
  }
  .swiper .swiper-wrapper {
    transition-timing-function: linear !important;
    display: flex;
    align-items: stretch;
  }
  .thumbnail {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    pointer-events: none;
    &:before {
      content: "";
      display: block;
      padding-top: 56.25%; // 16:9
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
    }
    a {
      pointer-events: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  .thumbnail_anchor {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    color: white;
    background-color: #232323bb;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    @include c.gentle_flex;
    font-weight: v.$fw_bold;
    padding: 20px;
    text-align: center;
    line-height: 1.5em;
    transition: 0.15s;
    @include c.mq_hover_device {
      &:hover {
        opacity: 1;
      }
    }
    .title {
      display: inline-block;
      font-size: 20px;
    }
    .categories {
      font-size: 12px;
      span {
        display: inline-block;
        &.category {
          margin-left: 4px;
        }
      }
    }
    @include c.tab {
      @include c.mq_hover_device {
        &:hover {
          opacity: 0.4;
        }
      }
      .title {
        display: none;
      }
      .categories {
        display: none;
      }
    }
  }
  #works_grid_wrapper {
    position: relative;
    #search_loading {
      display: none;
      &.above_active {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(0, -50%);
      }
      &.bottom_active {
        display: block;
        .c__loader {
          margin: -135px auto 120px;
        }
      }
    }
  }
  .categories_list {
    margin: 0 auto 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 14px 12px;
    align-items: center;
    justify-content: center;
    max-width: v.$pc_min_l;
    width: 100%;
    padding: 0 clamp(78px, 14vw, 154px);
    @include c.pc_m {
      padding: 0 clamp(40px, 7vw, 80px);
    }
    @include c.tab {
      justify-content: left;
      gap: 10px 8px;
      padding: 0 12px 0 35px;
    }
    @include c.sp {
      gap: 10px clamp(4px, 1.2vw, 8px);
      padding: 0 12px 0 35px;
    }
    li {
      display: inline-block;
      .category_buttons {
        display: flex;
        padding: 0 26px;
        height: 30px;
        font-size: 0.87rem;
        font-weight: 700;
        border: 2px solid #ffd800;
        border-radius: 100vh;
        transition: 0.15s;
        align-items: center;
        &.active {
          background-color: v.$kn_yellow;
        }
        @include c.mq_hover_device {
          &:hover {
            background-color: v.$kn_yellow;
          }
        }
        @include c.tab {
          font-size: 0.81rem;
          padding: 0 18px;
        }
      }
    }
  }
  .works_grid {
    display: grid;
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    margin-bottom: 200px;
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    .thumbnail {
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-30px);
      animation: appear_delay 0.5s ease forwards;
      @keyframes appear_delay {
        100% {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
        }
      }
      @include c.anim_delay;
    }
    @include c.sp {
      gap: 8px;
      grid-auto-rows: minmax(50px, auto);
      grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
    }
    &.no_result {
      display: block;
    }
    &.loading {
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;
    }
  }
  .scrollleft {
    display: inline-block;
    position: relative;
    margin: 30px 0;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #111;
    &::before {
      content: "";
      position: absolute;
      top: 24px;
      left: 16px;
      width: 70px;
      height: 1px;
      background-color: #aaa;
    }
    &::after {
      content: "";
      position: absolute;
      top: 23px;
      width: 3px;
      height: 3px;
      border-radius: 4px;
      animation: scrollleft 4.5s ease infinite;
      left: 16px;
      background-color: #111;
    }
    @keyframes scrollleft {
      0% {
        opacity: 0;
        transform: translateX(70px);
      }
      25% {
        opacity: 1;
      }
      50% {
        opacity: 0;
        transform: translateX(10px);
      }
      51% {
        opacity: 0;
        transform: translateX(0px);
      }
      75% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: translateX(60px);
      }
    }
  }
}
.p__about {
  margin-top: calc(v.$header_height + 120px);
  color: v.$letter_primary;
  margin-bottom: 200px;
  .c__head_primary {
    margin-bottom: 34px;
    .sub {
      font-size: 18px;
    }
  }
  .c__common_text_secondary {
    a {
      text-decoration: underline;
      &:hover {
        opacity: 0.7;
        transition: 0.15s;
      }
    }
  }
  .l__flexbox_pc_tab {
    gap: clamp(10px, 6vw, 52px);
  }
  .policy_img_pc {
    width: clamp(360px, 54vw, 470px);
  }
  .policy_img_sp {
    width: 83vw;
    max-width: 360px;
  }
  .logo {
    padding: 0 24px 0 28px;
    width: 216px;
  }
  .photo {
    width: clamp(160px, 23vw, 200px);
    height: clamp(160px, 23vw, 200px);
    min-width: clamp(160px, 23vw, 200px);
    min-height: clamp(160px, 23vw, 200px);
    position: relative;
    padding-top: v.$ratio_1_1;
    // height: 100%;
    transition: 0;
    img {
      position: absolute;
      top: 0;
      right: 0;
      width: clamp(160px, 23vw, 200px);
      height: clamp(160px, 23vw, 200px);
      object-fit: cover;
      border: v.$kn_yellow 7px solid;
      border-radius: 100vh;
    }
    // img {
    //   width: 100%;

    // }
  }
  .description {
    font-size: 18px;
    font-weight: v.$fw_bold;
    line-height: c.xd_line_height(18, 34);
    margin-bottom: 34px;
  }
  .c__triangle_wrapper {
    margin: 138px auto 186px;
  }
  .introduction {
    margin-bottom: 100px;
  }
  @include c.tab {
    .description {
      font-size: 17px;
    }
  }
  @include c.sp {
    margin-top: calc(v.$header_height_sp + 50px);
    margin-bottom: 100px;
    .c__head_primary {
      font-size: clamp(21px, 6vw, 24px);
      .sub {
        font-size: 16px;
      }
    }
    .logo {
      @include c.gentle_flex;
      padding: 0;
      width: 100px;
      margin-bottom: 60px;
    }
    .photo {
      @include c.gentle_flex;
      width: 180px;
      height: 180px;
      min-width: 180px;
      min-height: 180px;
      margin-bottom: 60px;
      padding-top: 0;
      img {
        height: 180px;
        width: 180px;
      }
    }
    .description {
      font-size: clamp(11px, 3.4vw, 14px);
      line-height: c.xd_line_height(14, 26);
      margin-bottom: clamp(20px, 6vw, 34px);
    }
    .c__triangle_wrapper {
      margin: 100px auto 84px;
    }
    .introduction {
      margin-bottom: 64px;
    }
  }
}
.p__single {
  margin-top: calc(160px + v.$header_height);
  .article_title {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .article_year {
    font-size: 14px;
    margin-bottom: 14px;
    font-weight: v.$fw_bold;
  }
  .thumbnail {
    position: relative;
    &:before {
      content: "";
      display: block;
      padding-top: 56.25%; // 16:9
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
    }
  }
  .related_images {
    img {
      margin-top: clamp(10px, 4vw, 36px);
    }
  }
  .wp_content_area {
    display: flex;
    margin-top: 34px;
  }
  .credit_notation {
    flex-basis: 40%;
    margin-right: 20px;
  }
  .content {
    flex-basis: 60%;
  }
  .prev_next {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    margin: 100px 0 200px;
    .prev {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      a {
        display: flex;
        align-items: center;
        text-align: left;
        flex-direction: row-reverse;
        transition: 0.15s;
        // &:hover {
        //   opacity: 0.7;
        // }
      }
      .arrow {
        margin-right: 16px;
      }
      .text {
        display: inline-block;
        text-align: left;
        width: 100%;
        line-height: 1.5;
      }
    }
    .next {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        display: flex;
        align-items: center;
        text-align: left;
        transition: 0.15s;
        // &:hover {
        //   opacity: 0.7;
        // }
      }
      .arrow {
        margin-left: 16px;
      }
      .text {
        text-align: left;
        width: 100%;
        line-height: 1.5;
      }
    }
  }
  @include c.sp {
    margin-top: calc(54px + v.$header_height_sp);
    .wp_content_area {
      margin-top: 24px;
      display: block;
    }
    .credit_notation {
      margin: 0 0 44px 0;
    }
    .prev_next {
      flex-direction: column;
      .prev {
        width: 100%;
      }
      .next {
        width: 100%;
      }
    }
  }
}
.p__contact {
  margin-top: 100px;
  .title_wrapper {
    padding-top: 100px;
    text-align: center;
  }
  .c__head_primary {
    margin-bottom: 42px;
  }
  .c__head_secondary {
    margin-bottom: 94px;
  }
  @include c.sp {
    .title_wrapper {
      padding-top: 50px;
      text-align: left;
    }
    .c__head_primary {
      margin-bottom: 10px;
      font-size: 24px;
    }
    .c__head_secondary {
      margin-bottom: 70px;
      font-size: 14px;
      line-height: 26px;
    }
  }
}
.p__contact_input {
  #js_err_existence {
    color: v.$notify_red;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 1.4em;
  }
  .mw_wp_form .horizontal-item + .horizontal-item {
    margin: 0;
    display: inline-block;
  }
  .mwform-checkbox-field label,
  .mwform-radio-field label {
    cursor: pointer;
    margin: 0 10px 16px 0;
    display: inline-block;
  }
  .c__contact_label {
    margin-bottom: 10px;
  }
  .c__contact_text {
    margin-bottom: 24px;
  }
  .postal_wrapper {
    position: relative;
    &::before {
      @include c.postal_icon;
      position: absolute;
      top: 18px;
      left: 22px;
      color: #000;
    }
    .c__contact_text {
      padding: 16px 20px 16px 44px;
    }
  }
  .textarea_wrapper {
    margin-bottom: 24px;
    border-radius: 24px;
    overflow: hidden;
  }
  #checkbox_wrapper {
    margin-bottom: 36px;
  }
  .input span.error {
    font-size: 14px;
    color: #db0000;
    display: block;
    margin: -8px 0 40px 0;
    line-height: 1.4em;
    &:not(:first-of-type) {
      margin: -24px 0 40px 0;
    }
  }
  .error_field {
    background-color: v.$error_red;
  }
  .agreement {
    font-size: 15px;
    label {
      margin: 0 0 40px 0;
    }
    .notice {
      margin: -11px 0 0 4px;
    }
    a {
      text-decoration: underline;
      margin-right: 8px;
      line-height: 38px;
      &:hover {
        transition: 0.15s;
        opacity: 0.7;
      }
      i {
        margin-left: 4px;
      }
    }
    &.input span.error {
      margin: -22px 0 40px 0;
    }
    .c__contact_label {
      margin: 50px 0 20px;
    }
  }
  .c__submit_primary {
    margin: 0 auto 200px;
  }
  @include c.sp {
    .mw_wp_form .horizontal-item + .horizontal-item {
      display: block;
    }
    #checkbox_wrapper {
      margin-bottom: 24px;
    }
    .c__submit_primary {
      margin: 0 auto 100px;
    }
    .agreement {
      &.input span.error {
        margin: -28px 0 40px 0;
      }
      .notice {
        margin: -11px 0 40px 4px;
      }
    }
  }
}
.p__contact_confirm {
  form {
    .input {
      line-height: 24px;
      margin: 0 0 0 34px;
    }
    .postal_wrapper {
      position: relative;
      &.postal_icon {
        &::before {
          @include c.postal_icon;
          position: absolute;
          top: 6px;
          left: 38px;
          color: #000;
        }
        .input {
          padding: 0 0 0 26px;
        }
      }
      p {
        margin: 0 0 0 34px;
      }
    }
    .c__contact_label {
      margin: 36px 0 26px;
    }
    .textarea_wrapper {
      line-height: 24px;
    }
    #checkbox_wrapper ul li {
      list-style: disc;
      margin: 0 0 10px 18px;
    }
    .agreement {
      display: none;
    }
    .submit_wrapper {
      margin: 200px 0;
      .correct_link {
        display: block;
        font-size: 16px;
        font-family: YakuHanJP, "Noto Sans JP", "Helvetica", "arial", sans-serif;
        text-decoration: none;
        color: #232323;
        border: solid 0px #ffffff00;
        transition: 0.1s;
        cursor: pointer;
        background-color: white;
        margin: 0 auto;
        text-decoration: underline;
        &:hover {
          transition: 0.15s;
          opacity: 0.7;
        }
      }
      .c__submit_primary {
        margin: 0 auto 40px;
      }
      @include c.sp {
        display: block;
        margin: 60px 0 100px;
      }
    }
  }
}
.p__contact_submitted {
  .c__common_text {
    margin-bottom: 20px;
  }
  .c__submit_primary {
    margin: 40px auto 100px;
  }
}
.p__terms {
  &.l__content_width_l {
    max-width: calc(1026px + v.$body_pd + v.$body_pd);
  }
  margin: calc(v.$header_height + 100px) auto;
  .c__head_primary {
    text-align: center;
    margin-bottom: 226px;
    font-size: clamp(23px, 7vw, 30px);
  }
  .flex {
    display: flex;
    align-items: flex-start;
    margin-bottom: 60px;
    gap: min(4vw, 100px);
    .head {
      display: flex;
      justify-content: flex-start;
      width: clamp(60px, 20vw, 300px);
      text-align: left;
    }
    .content {
      flex: 1;
    }
  }
  .h2 {
    margin: -10px 0 10px;
    line-height: c.xd_line_height(20, 50);
  }
  .h3 {
    margin: 0 0 10px;
    font-size: 14px;
  }
  .text {
    margin-bottom: 12px;
  }
  .ul {
    padding: 0 0 0 34px;
    margin-bottom: 12px;
  }
  .ol {
    padding: 0 0 0 52px;
    margin-bottom: 12px;
  }
  .updated {
    display: flex;
    margin-top: 80px;
    flex-direction: column;
    text-align: right;
  }
  @include c.tab {
    .c__head_primary {
      text-align: left;
      margin-bottom: 40px;
    }
    .flex {
      display: block;
      margin-bottom: 30px;
      .head {
        display: block;
        width: auto;
        margin-left: 0;
        text-align: left;
      }
      .content {
        margin-left: 0;
      }
    }
    .h2 {
      margin: 0 0 10px;
    }
    .h3 {
      margin: 0 0 10px;
    }
  }
  @include c.sp {
    margin-top: calc(v.$header_height_sp + 100px);
  }
}
.p__notfound {
  margin-top: 24vh;
  text-align: center;
  .c__head_primary {
    margin: 0 auto 20px;
    @include c.sp {
      font-size: 26px;
    }
  }
  .c__common_text {
    margin: 0 auto 20px;
    @include c.sp {
      font-size: 12px;
    }
  }
  .c__submit_primary {
    margin: 60px auto 100px;
  }
}

/*=======================================
  フッター
=======================================*/
#footer {
  width: 100%;
  height: 300px;
  font-family: v.$noto;
  color: v.$letter_primary;
  z-index: 10;
  background: v.$kn_yellow;
  transition: 0.5s;
  padding: 52px 0 0;
  @include c.sp {
    height: 330px;
  }
  .footer_nav {
    .nav {
      font-size: 14px;
      font-weight: v.$fw_bold;
      margin-bottom: 18px;
      li {
        margin-right: 32px;
        @include c.sp {
          margin: 0 0 16px;
        }
      }
    }
    .footer_sns {
      margin-bottom: 34px;
      .icon {
        margin-right: 30px;
        height: 30px;
        width: 30px;
        a {
          display: block;
          width: 100%;
          height: 100%;
          img {
            width: auto;
            height: 100%;
          }
        }
      }
      .tw {
        // width: 36px;
        margin-right: 28px;
        height: 30px;
      }
      // .ig {
      //   width: 30px;
      // }
      // .fb {
      //   width: 30px;
      // }
    }
    .privacy {
      margin-bottom: 72px;
      font-size: 14px;
      @include c.sp {
        margin-bottom: 58px;
      }
    }
    .copyright {
      font-weight: v.$fw_normal;
      font-size: 14px;
    }
  }
}

/*=======================================
  マウスストーカー
=======================================*/
#mouse_stalker {
  pointer-events: none;
  z-index: 9500;
  @include c.mq_hover_device {
    opacity: 0;
    &.loaded {
      opacity: 0.7;
    }
    position: fixed;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    background: v.$kn_yellow;
    border-radius: 100vh;
    transform: translate(0, 0);
    transition: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
    // ホバーした際の挙動
    &.stalker_cursor_pointer {
      top: -25px;
      left: -25px;
      width: 50px;
      height: 50px;
      opacity: 0.5;
    }
  }
  @include c.mq_touch_device {
    display: none;
  }
}

#loader_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: all 0.2s;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9000;
  &.loaded {
    opacity: 0;
    visibility: hidden;
    //フェードさせるためdisplay:none不使用
  }
}
