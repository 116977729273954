@use "../foundation/variables" as v;

/*=======================================
  ページ制御系
=======================================*/
@mixin pc_l {
  @media (min-width: (v.$pc_min_l)) {
    @content;
  }
}
@mixin pc_m {
  @media (max-width: (v.$pc_min_m)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: (v.$tab_max)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: (v.$sp_max)) {
    @content;
  }
}
@mixin mq_hover_device {
  @media (any-hover: hover) {
    @content;
  }
}
@mixin mq_touch_device {
  @media (any-hover: none) {
    @content;
  }
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  -webkit-text-size-adjust: 100%; //一部のモバイル端末で使われる、テキストの自動拡大アルゴリズムを制御
  font-family: v.$noto;
  font-size: 16px;
  color: v.$letter_primary;
  overflow-y: scroll;
  background: #fff;
  ::selection {
    color: v.$selection_text;
    background: v.$selection_bg;
  }
}
img {
  pointer-events: none; //リンクになっている画像をクリックした際、aのeをちゃんと取得できるようにする
  &::selection {
    color: v.$selection_text;
    background: rgba(0, 0, 0, 0.07);
  }
}
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  &:link {
    color: inherit;
  }
  &:visited {
    color: inherit;
  }
  &:hover {
    color: inherit;
  }
  &:active {
    color: inherit;
  }
}

.pc_none {
  display: none;
  @include tab {
    display: block;
  }
  @include sp {
    display: block;
  }
}
.pc_only {
  display: block;
  @include tab {
    display: none;
  }
  @include sp {
    display: none;
  }
}
.tab_block {
  display: none;
  @include tab {
    display: block;
  }
}
.tab_only_block {
  display: none;
  @include tab {
    display: block;
  }
  @include sp {
    display: none;
  }
}
.tab_none {
  display: block;
  @include tab {
    display: none;
  }
}
.sp_none {
  display: block;
  @include sp {
    display: none;
  }
}
.sp_only {
  display: none;
  @include sp {
    display: block;
  }
}
@mixin gentle_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1ch;
  margin: 0 auto;
}
/*=======================================
  アニメーションディレイ
=======================================*/
@mixin anim_delay($magnif: 0.06) {
  @for $i from 0 through 12 {
    &.delay#{$i} {
      animation-delay: #{$i * $magnif}s;
    }
  }
}
@mixin tran_delay($magnif: 0.06) {
  @for $i from 0 through 12 {
    &.delay#{$i} {
      transition-delay: #{$i * $magnif}s;
    }
  }
}

/*=======================================
  関数
=======================================*/
//XDの文字間隔をCSS用に変換
@function xd_letter_spacing($tracking) {
  @return calc($tracking / 1000) + "em";
}
//XDの行間をCSS用に変換
@function xd_line_height($font_size, $line_height) {
  @return calc($line_height / $font_size);
}
//単位を取り除く
@function trim_unit($number) {
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  @return $number / ($number * 0 + 1);
}
// アドレスバーを含まないvhを取得
@function svh($num) {
  @return calc((var(--vh, 1vh) * $num));
}

/*=======================================
  パーツ系
=======================================*/
@mixin postal_icon {
  content: "\f7d7";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: v.$fontawesome_fas;
}