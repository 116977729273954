@use "../foundation/variables" as v;

.u__inline {
  display: inline;
}
.u__block {
  display: block;
}
.u__inline_block {
  display: inline-block;
}